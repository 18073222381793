<template>
    <b-container>
        <b-row class="justify-content-center mt-4">
            <b-col md="7">
                <iq-card>
                             <router-link
                                        class="btn btn-primary float-right"
                                        type="button" size="sm"
                                        to="/dealer-panel/profile-form">
                                        {{ $t('nav.user.profileEditTitle') }}
                                        <i class="far fa-edit"></i>
                                    </router-link>
                    <itemView :prop-data="itemsToView" :loading="!dataFetched" :key="itemViewKey">
                        <!-- <template v-slot:সংরক্ষিত="">
                          <b-row>
                            <b-col md="3"><b-badge variant="success">Saved</b-badge></b-col>
                            <b-col md="9" style="right: -17rem;">
                              <router-link
                                  class="btn btn-primary float-right"
                                  type="button" size="sm"
                                  to="/warehouse-farmer/profile-form">
                                {{ $t('nav.user.profileEditTitle') }}
                                <i class="far fa-edit"></i>
                              </router-link>
                            </b-col>
                          </b-row>
                        </template> -->
                        <!-- <template v-slot:সংরক্ষিত="">
                          <b-row>
                            <b-col md="3"><b-badge variant="success">সংরক্ষিত</b-badge></b-col>
                            <b-col md="9" style="right: -17rem;" v-if="!(farmerBasicInfo.status === 3 || farmerBasicInfo.status === 1)">
                              <router-link
                                  class="btn btn-primary float-right"
                                  type="button" size="sm"
                                  to="/dealer/profile-form">
                                {{ $t('nav.user.profileEditTitle') }}
                                <i class="far fa-edit"></i>
                              </router-link>
                            </b-col>
                          </b-row>
                        </template> -->
                        <template v-for="(slot, index) in ['draft', 'ড্রাফট']" v-slot:[slot]="">
                            <b-row :key="index">
                                <b-col md="3">
                                    <b-badge variant="danger">Draft</b-badge>
                                </b-col>
                                <b-col md="9" style="right: -17rem;"  v-if="!(farmerBasicInfo.status === 3 || farmerBasicInfo.status === 1)">
                                    <router-link
                                        class="btn btn-primary float-right"
                                        type="button" size="sm"
                                        to="/dealer-panel/profile-form">
                                        {{ $t('nav.user.profileEditTitle') }}
                                        <i class="far fa-edit"></i>
                                    </router-link>
                                </b-col>
                            </b-row>
                        </template>
                    </itemView>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { helpers } from '@/mixins/helper-functions'
import itemView from '@/components/structure/itemView'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { profileListData } from '../../../api/routes'

var inc = 0

export default {
    name: 'Show',
    components: { itemView },
    created () {
        this.getFarmer()
        // this.getWarehouseTypeList()
        // this.getRegionList()
        // this.getWarehouseName()
        const tmp = this.getDetails
        this.farmerBasicInfo = this.formatList(tmp)
    },
    data () {
        return {
            itemViewKey: 1,
            farmerBasicInfo: {},
            dataFetched: false,
            regionLists: [],
            warehouseTypeList: [],
            warehouseNameList: [],
            landTypeList: [
                { text_en: 'Own', text_bn: 'নিজস্ব', value: 1 },
                { text_en: 'Lease', text_bn: 'ইজারা', value: 2 }
            ]
        }
    },
    computed: {
        isBn () {
            return this.$i18n.locale === 'bn'
        },
        retigonData () {
             return this.regionLists.find(item => item.value === this.farmerBasicInfo.region_id)
        },
        landTypeData () {
             const data = this.landTypeList.find(item => item.value === this.farmerBasicInfo.land_type_id)
            return data
        },
        warehouseTypeData () {
             const data = this.warehouseTypeList.find(item => item.value === this.farmerBasicInfo.warehouse_type_id)
            if (typeof data !== 'undefined') {
                return data.text
            } else {
                return 'N/A'
            }
        },
        warehuseNameData () {
            return this.warehouseNameList.find(item => item.value === this.farmerBasicInfo.warehouse_id)
        },
        getDetails () {
            return this.$store.state.ExternalUserIrrigation.farmerWareInfo
        },
        itemsToView () {
            const personalFormatted = [
                {
                    title1: this.$t('globalTrans.name'),
                    value1: this.isBn ? this.farmerBasicInfo.name_bn : this.farmerBasicInfo.name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('cardPayment.father_name'),
                    value1: this.isBn ? this.farmerBasicInfo.father_name_bn : this.farmerBasicInfo.father_name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('user.phone_no'),
                    value1: this.farmerBasicInfo.contact_no,
                    title2: this.$t('cardPayment.nid'),
                    value2: this.farmerBasicInfo.nid
                }
                // {
                //     title1: this.$t('externalUserIrrigation.beneficiary_type'),
                //     value1: this.isBn ? this.farmerBasicInfo.service_type_bn : this.farmerBasicInfo.service_type,
                //     colSpan: {
                //         title1: 1,
                //         value1: 3
                //     }
                // },
                // {
                //     title1: this.$t('warehouse_config.warehouse_type'),
                //     value1: this.warehouseTypeData,
                //     title2: this.$t('warehouse_config.warehouse_name'),
                //     value2: typeof this.warehuseNameData !== 'undefined' ? this.warehuseNameData.text : 'N/A'
                // },
                // {
                //     title1: this.$t('externalUserIrrigation.land_type'),
                //     value1: landType,
                //     title2: this.$t('externalUserIrrigation.land_area'),
                //     value2: this.farmerBasicInfo.land_area
                // }
            ]
            const addressFormatted = [
                {
                    title1: this.$t('complain.division'),
                    value1: this.isBn ? this.farmerBasicInfo.division_name_bn
                        : this.farmerBasicInfo.division_name,
                    title2: this.$t('externalUserIrrigation.region'),
                    value2: typeof this.retigonData !== 'undefined' ? this.retigonData.text : 'N/A'
                },
                {
                    title1: this.$t('complain.district'),
                    value1: this.isBn ? this.farmerBasicInfo.district_name_bn
                        : this.farmerBasicInfo.district_name,
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                },
                {
                    title1: this.$t('complain.upazila'),
                    value1: this.isBn ? this.farmerBasicInfo.upazilla_name_bn
                        : this.farmerBasicInfo.upazilla_name,
                    title2: this.$t('complain.union'),
                    value2: this.isBn ? this.farmerBasicInfo.union_name_bn
                        : this.farmerBasicInfo.union_name
                },
                 {
                    title1: this.$t('externalUserIrrigation.address'),
                    value1: this.isBn ? this.farmerBasicInfo.pre_address_bn
                        : '',
                    colSpan: {
                        title1: 1,
                        value1: 3
                    }
                }
            ]
            // const applicationFormatted = [
            //     {
            //         title2: this.$t('globalTrans.status'),
            //         value2: this.isBn ? this.farmerBasicInfo.status_bn
            //             : this.farmerBasicInfo.status_en,
            //         lg: '4,8'
            //     }
            // ]
            return {
                title: '',
                data:
                [
                    {
                        title: this.$t('globalTrans.personal_information'),
                        type: 'table',
                        data: personalFormatted
                    },
                    {
                        title: this.$t('globalTrans.address'),
                        type: 'table',
                        data: addressFormatted
                    }
                    // {
                    //     title: this.$t('globalTrans.application_info'),
                    //     type: 'list',
                    //     data: applicationFormatted
                    // }
                ]
            }
        }
    },
    watch: {
        getDetails: function (newVal) {
            inc++
            this.itemViewKey = inc
            const tmp = this.getDetails
            this.farmerBasicInfo = this.formatList(tmp)
        }
    },
    methods: {
        viewItems () {
            return this.itemsToView
        },
        formatList (data) {
            const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
            const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
            const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
            const statusList = this.$store.state.ExternalUserIrrigation.farmerBasicInfoStatus

            var division = divisionList.find(item => item.value === data.dealer_details.pre_division_id)
            var district = districtList.find(item => item.value === data.dealer_details.pre_district_id)
            var upazilla = upazilaList.find(item => item.value === data.dealer_details.pre_upazilla_id)
            var union = unionList.find(item => item.value === data.dealer_details.pre_union_id)
            var status = statusList.find(item => item.value === data.save_status)

            let divisionData = {}
            let districtData = {}
            let upazillaData = {}
            let unionData = {}
            let statusData = {}

            if (division) {
               divisionData = { division_name: division.text_en, division_name_bn: division.text_bn }
            }
            if (district) {
               districtData = { district_name: district.text_en, district_name_bn: district.text_bn }
            }
            if (upazilla) {
               upazillaData = { upazilla_name: upazilla.text_en, upazilla_name_bn: upazilla.text_bn }
            }
            if (union) {
               unionData = { union_name: union.text_en, union_name_bn: union.text_bn }
            }
            if (status) {
               statusData = { status_en: status.text, status_bn: status.text_bn }
            }

            const mergedObj = Object.assign({}, data, divisionData,
             districtData, upazillaData, unionData, statusData)

            const personalItems = [
                'name',
                'name_bn',
                'father_name', 'father_name_bn',
                'contact_no',
                'nid',
                'service_type', 'service_type_bn',
                'district_name', 'district_name_bn',
                'division_name', 'division_name_bn',
                'upazilla_name', 'upazilla_name_bn',
                'address', 'address_bn',
                'warehouse_type_id',
                'region_id',
                'land_type_id',
                'land_area',
                'warehouse_id',
                'union_name', 'union_name_bn',
                'status_en',
                'status_bn',
                'status'
            ]
            return helpers.filterObjectByArrayItem(mergedObj, personalItems)
        },
        async getFarmer () {
            try {
                const result = await RestApi.getData(seedFertilizerServiceBaseUrl, profileListData)
                if (result.success) {
                    this.$store.dispatch('ExternalUserIrrigation/getfarmerWareInfoDetails', result.data)
                }
                this.dataFetched = true
            } catch (error) {
                this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
                this.dataFetched = true
            }
        },
        getRegionList () {
            RestApi.getData(seedFertilizerServiceBaseUrl, '/master-region-info/list-all').then(response => {
                if (response.success) {
                    this.regionLists = response.data.map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.id, text: obj.region_name_bn }
                        } else {
                            return { value: obj.id, text: obj.region_name }
                        }
                    })
                }
            })
        },
        getWarehouseTypeList () {
            RestApi.getData(seedFertilizerServiceBaseUrl, 'master-warehousetype/list-all').then(response => {
                if (response.success) {
                    this.warehouseTypeList = response.data.map(obj => {
                        if (this.$i18n.locale === 'bn') {
                        return { value: obj.id, text: obj.warehouse_type_name_bn }
                        } else {
                        return { value: obj.id, text: obj.warehouse_type_name }
                        }
                    })
                }
            })
        },
        getWarehouseName (typeId) {
            RestApi.getData(seedFertilizerServiceBaseUrl, 'master-warehouse-info/list-allOp').then(response => {
                this.warehouseNameList = response.data.map((obj) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.warehouse_name_bn }
                } else {
                    return { value: obj.id, text: obj.warehouse_name }
                }
                })
            })
        }
    }
}
</script>
